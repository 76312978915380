/**
=========================================================
* EGEAD Fulfilment POD - v2.2.0
=========================================================

* Product Page: https://www.egeadcompany.com/product/material-dashboard-react
* Copyright 2023 Dev Egead Company (https://www.egeadcompany.com)

Coded by www.egeadcompany.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// EGEAD Fulfilment POD components
import MDBox from "components/MDBox";

// EGEAD Fulfilment POD example components
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAds } from "features/slices";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
);

function formatValue(value) {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + "M"; // Chuyển đổi sang triệu
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + "K"; // Chuyển đổi sang nghìn
  } else {
    return value.toString(); // Giữ nguyên giá trị nhỏ hơn 1000
  }
}

const MultiLineChart = ({ storeData }) => {
  // Helper function to scale data proportionally to fit within same range for chart display

  // Generate a chart for each campaign
  const generateCharts = (storeData) => {
    const charts = [];

    storeData.forEach((store, storeIndex) => {
      Object.entries(store.data).forEach(([campaignName, campaignData], campaignIndex) => {
        const dates = campaignData.map((entry) => entry.date);
        const impressions = campaignData.map((entry) => entry.impressions);
        const clicks = campaignData.map((entry) => entry.clicks);
        const costs = campaignData.map((entry) => entry.cost);
        const conversionValues = campaignData.map((entry) => entry.conversionValue);

        const totalImpressions = impressions.reduce((a, b) => a + b, 0);
        const totalClicks = clicks.reduce((a, b) => a + b, 0);
        const totalCosts = costs.reduce((a, b) => a + b, 0);
        const totalConversionValues = conversionValues.reduce((a, b) => a + b, 0);


        const chartData = {
          labels: dates,
          datasets: [
            {
              label: "Impressions",
              data: impressions,
              borderColor: "#d93025",
              yAxisID: "y1",
              pointRadius: 0,
              borderWidth: 2
            },
            {
              label: "Clicks",
              data: clicks,
              borderColor: "#1a73e8",
              yAxisID: "y2",
              pointRadius: 0,
              borderWidth: 2
            },
            {
              label: "Costs",
              data: costs,
              borderColor: "#1e8e3e",
              yAxisID: "y2",
              pointRadius: 0,
              borderWidth: 2
            },
            {
              label: "Conversion Value",
              data: conversionValues,
              borderColor: "#f9ab00",
              yAxisID: "y2",
              pointRadius: 0,
              borderWidth: 2
            },
          ],
        };

        const options = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
              text: `${store.store} - ${campaignName} - $${(store.revenue || 0).toFixed(2)}`,
            },
            tooltip: {
              mode: "index",
              intersect: false,
              callbacks: {
                label: (tooltipItem) => {
                  return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                },
              },
            },
          },
          hover: {
            mode: "index",
            intersect: false,
          },
          scales: {
            y1: {
              type: "linear",
              position: "left",
              title: {
                display: true,
              },
              ticks: {
                beginAtZero: true,
                min: 0,
                display: false,
              },
              grid: {
                drawTicks: false,
                drawBorder: false,
                drawOnChartArea: true,
                color: "rgba(200, 200, 200, 0.2)",
              },
            },
            y2: {
              type: "linear",
              position: "right",
              title: {
                display: true,
              },
              ticks: {
                display: false,
                beginAtZero: true,
                min: 0,

              },
              grid: {
                drawTicks: false, // Không vẽ các dấu tick
                drawBorder: false, // Không vẽ đường viền của trục y1
                drawOnChartArea: false,
              },
            },
            x: {
              grid: {
                drawOnChartArea: false,
              },
            },
          },
        };

        charts.push(
          <Grid item xs={12} md={6} lg={6}>
            <Card key={`${storeIndex}-${campaignIndex}`} style={{ display: "inline-block", width: "100%" }}>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ backgroundColor: "#f9ab00", color: "white", borderRadius: "10px 0 0 0" }}>
                    <div style={{ margin: "16px 50px 16px 16px" }}>
                      <p style={{
                        fontFamily: "Roboto, Arial, sans-serif",
                        lineHeight: "1.25rem",
                        fontSize: "0.875rem",
                        letterSpacing: "0.0142857143em",
                        fontWeight: 400,
                        whiteSpace: "nowrap"
                      }}>Clicks:</p>
                      <div style={{
                        fontFamily: "'Google Sans', Roboto, Arial, sans-serif",
                        lineHeight: "2.5rem",
                        fontSize: "26px",
                        letterSpacing: "0",
                        fontWeight: 400,
                        transformOrigin: "left",
                        whiteSpace: "nowrap"
                      }}>{totalClicks}</div>
                    </div>
                  </div>
                  <div style={{ backgroundColor: "#1a73e8", color: "white" }}>
                    <div style={{ margin: "16px 50px 16px 16px" }}>
                      <p style={{
                        fontFamily: "Roboto, Arial, sans-serif",
                        lineHeight: "1.25rem",
                        fontSize: "0.875rem",
                        letterSpacing: "0.0142857143em",
                        fontWeight: 400,
                        whiteSpace: "nowrap"
                      }}>Impressions:</p>
                      <div style={{
                        fontFamily: "'Google Sans', Roboto, Arial, sans-serif",
                        lineHeight: "2.5rem",
                        fontSize: "26px",
                        letterSpacing: "0",
                        fontWeight: 400,
                        transformOrigin: "left",
                        whiteSpace: "nowrap"
                      }}>{formatValue(totalImpressions)}</div>
                    </div>
                  </div>
                  <div style={{ backgroundColor: "#d93025", color: "white" }}>
                    <div style={{ margin: "16px 50px 16px 16px" }}>
                      <p style={{
                        fontFamily: "Roboto, Arial, sans-serif",
                        lineHeight: "1.25rem",
                        fontSize: "0.875rem",
                        letterSpacing: "0.0142857143em",
                        fontWeight: 400,
                        whiteSpace: "nowrap"
                      }}>Conv. Value:</p>
                      <div style={{
                        fontFamily: "'Google Sans', Roboto, Arial, sans-serif",
                        lineHeight: "2.5rem",
                        fontSize: "26px",
                        letterSpacing: "0",
                        fontWeight: 400,
                        transformOrigin: "left",
                        whiteSpace: "nowrap"
                      }}> {formatValue(totalConversionValues.toFixed(2))}</div>
                    </div>
                  </div>
                  <div style={{ backgroundColor: "#1e8e3e", color: "white" }}>
                    <div style={{ margin: "16px 50px 16px 16px" }}>
                      <p style={{
                        fontFamily: "Roboto, Arial, sans-serif",
                        lineHeight: "1.25rem",
                        fontSize: "0.875rem",
                        letterSpacing: "0.0142857143em",
                        fontWeight: 400,
                        whiteSpace: "nowrap"
                      }}>Costs</p>
                      <div style={{
                        fontFamily: "'Google Sans', Roboto, Arial, sans-serif",
                        lineHeight: "2.5rem",
                        fontSize: "26px",
                        letterSpacing: "0",
                        fontWeight: 400,
                        transformOrigin: "left",
                        whiteSpace: "nowrap"
                      }}>{`$${formatValue(totalCosts.toFixed(2))}`}</div>
                    </div>
                  </div>
                  <div style={{ backgroundColor: "#f9ab00", color: "white" }}>
                    <div style={{ margin: "16px 50px 16px 16px" }}>
                      <p style={{
                        fontFamily: "Roboto, Arial, sans-serif",
                        lineHeight: "1.25rem",
                        fontSize: "0.875rem",
                        letterSpacing: "0.0142857143em",
                        fontWeight: 400,
                        whiteSpace: "nowrap"
                      }}>Actual ROAS</p>
                      <div style={{
                        fontFamily: "'Google Sans', Roboto, Arial, sans-serif",
                        lineHeight: "2.5rem",
                        fontSize: "26px",
                        letterSpacing: "0",
                        fontWeight: 400,
                        transformOrigin: "left",
                        whiteSpace: "nowrap"
                      }}>{`${(totalConversionValues ? totalConversionValues / totalCosts * 100 : 0).toFixed(2)}%`}</div>
                    </div>
                  </div>
                </div>
                <div>

                </div>
              </div>
              <div style={{ height: "192px", width: "100%" }}>
                <Line data={chartData} options={options} />
              </div>
              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "center" }}> <span style={{ fontWeight: "bold", marginRight: 5 }}>{store.store.toUpperCase()} </span><div><span style={{ color: "blue", marginRight: 5 }}>{`Cam: ${campaignName}  `}</span><span style={{ color: "green" }}> {`$${(store.revenue || 0).toFixed(2)}`}</span></div></div>

            </Card ></Grid>
        );
      });
    });

    return charts;
  };

  return generateCharts(storeData)
};


function Ads() {
  const data = useSelector((state) => state.user.data || []);
  const startDate = useSelector((state) => state.user.startDate);
  const endDate = useSelector((state) => state.user.endDate);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      getAds({ from: moment(startDate).format("DD/MM/YYYY"), to: moment(endDate).format("DD/MM/YYYY") })
    );
  }, [startDate, endDate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={4}>
          {data.length ? <MultiLineChart storeData={data} /> : null}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout >
  );
}
MultiLineChart.propTypes = {
  storeData: PropTypes.arrayOf(
    PropTypes.shape({
      store: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string.isRequired,
          impressions: PropTypes.number.isRequired,
          clicks: PropTypes.number.isRequired,
          cost: PropTypes.number.isRequired,
          conversionValue: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default Ads;
